<template>
  <div class="row">
    <div v-if="isCheck" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="설비 기본정보" class="cardClassDetailInfo" topClass="topcolor-orange">
        <template slot="card-button">
          <q-btn-group outline >
              <c-btn label="기기정비 이력기록서" icon="assignment" @btnClicked="btnRecord" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div  class="col-3">
            <c-label-text title="점검부서" :value="selectedRow.deptNm"></c-label-text>
          </div>
          <div  class="col-3">
            <c-label-text title="설비유형" :value="selectedRow.equipmentType"></c-label-text>
          </div>
          <div  class="col-3">
            <c-label-text title="설비코드" :value="selectedRow.equipmentCd"></c-label-text>
          </div>
          <div  class="col-3">
            <c-label-text title="설비명" :value="selectedRow.equipmentNm"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="정비유형" :value="selectedRow.safCheckTypeNm"></c-label-text>
          </div>
          <div  class="col-3">
            <c-label-text title="이전점검일" :value="selectedRow.befInspYear"></c-label-text>
          </div>
          <div  class="col-3">
            <c-label-text title="점검주기" :value="selectedRow.inspCycle"></c-label-text>
          </div>
          <div  class="col-3">
            <c-label-text title="차기법정예정월" :value="selectedRow.nextInspMon"></c-label-text>
          </div>
          <div  class="col-3">
            <c-label-text title="실제점검자" :value="selectedRow.chkUserNm"></c-label-text>
          </div>
          <div  class="col-3">
            <c-label-text title="점검완료일" :value="selectedRow.chkSchCompleYmd"></c-label-text>
          </div>
          <div  class="col-3">
            <c-label-text title="점검결과" :value="selectedRow.chkResult"></c-label-text>
          </div>
          <div  class="col-3">
            <c-label-text title="관련법규" :value="selectedRow.relatedLaws"></c-label-text>
          </div>
          <div  class="col-9">
            <c-label-text title="점검결과요약" :value="selectedRow.chkRsltSmry"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div v-if="isCheck" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" topClass="topcolor-orange">
      <c-table
        ref="table"
        title="설비별 점검항목"
        :columns="grid2.columns"
        :gridHeight="grid2.height"
        :data="grid2.data"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        noDataLabel="설비에 대한 점검항목이 없습니다."
        rowKey="checkItemSeq"
        cardClass="topcolor-orange"
      >
        <template slot="table-button">
        </template>
      </c-table>
    </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card class="cardClassDetailInfo" title="설비 기본정보" topClass="topcolor-orange">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn label="기기정비 이력기록서" icon="assignment" @btnClicked="btnRecord" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-3">
              <c-label-text title="정비부서" :value="selectedRow.deptNm"></c-label-text>
            </div>
            <div class="col-3">
              <c-label-text title="설비유형" :value="selectedRow.equipmentType"></c-label-text>
            </div>
            <div class="col-3">
              <c-label-text title="설비코드" :value="selectedRow.equipmentCd"></c-label-text>
            </div>
            <div class="col-3">
              <c-label-text title="설비명" :value="selectedRow.equipmentNm"></c-label-text>
            </div>
            <div class="col-3">
              <c-label-text title="정비유형" :value="selectedRow.safCheckTypeNm"></c-label-text>
            </div>
            <div class="col-3">
              <c-label-text title="정비부서 입회자" :value="selectedRow.chkUserNm"></c-label-text>
            </div>
            <div  class="col-3">
              <c-label-text title="이전정비일" :value="selectedRow.befInspYear"></c-label-text>
            </div>
            <div  class="col-3">
              <c-label-text title="실제정비자" :value="selectedRow.chkUserNm"></c-label-text>
            </div>
            <div  class="col-3">
              <c-label-text title="정비완료일" :value="selectedRow.chkSchCompleYmd"></c-label-text>
            </div>
            <div  class="col-3">
              <c-label-text title="정비주기" :value="selectedRow.inspCycle"></c-label-text>
            </div>
            <div  class="col-3">
              <c-label-text title="차기법정예정월" :value="selectedRow.nextInspMon"></c-label-text>
            </div>
            <div  class="col-3">
              <c-label-text title="관련법규" :value="selectedRow.relatedLaws"></c-label-text>
            </div>
            <div class="col-12">
            </div>
          </template>
        </c-card>
      </div>
      <div v-if="!isCheck" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card class="cardClassDetailForm" title="설비 정비결과" topClass="topcolor-orange" bgClass="">
          <template slot="card-detail">
            
            <div  class="col-3">
              <c-label-text title="정비후 점검결과" :value="selectedRow.chkResult"></c-label-text>
            </div>
            <div  class="col-3">
              <c-label-text title="정비시간" :value="selectedRow.chkMaintenanceYmd"></c-label-text>
            </div>
            <div  class="col-3">
              <c-label-text title="운전정지시간" :value="selectedRow.chkStopYmd"></c-label-text>
            </div>
            <div  class="col-3">
              <c-label-text title="정비자 자격사항" :value="selectedRow.chkUserQualify"></c-label-text>
            </div>
            <div  class="col-12">
              <c-label-text title="정비원인" :value="selectedRow.chkRsltCause"></c-label-text>
            </div>
            <div  class="col-12">
              <c-label-text title="정비내용" :value="selectedRow.chkRsltContent"></c-label-text>
            </div>
            <div  class="col-12">
              <c-label-text title="정비결과요약" :value="selectedRow.chkRsltSmry"></c-label-text>
            </div>
            <div  class="col-12">
              <c-label-text title="관리자의 검토의견" :value="selectedRow.managerOpinion"></c-label-text>
            </div>
          </template>
        </c-card>
      </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'equipment-record-tab',
  props: {
    selectedRow: {
      type: Object,
      default: () => ({
        equipmentCheckSeq: 1,
        plantNm: "",
        deptNm: "",
        equipmentCd: "",
        statusTypeCd: "",
        statusTypeNm: "",
        safCheckTypeNm: "",
        safChkStepCd: '',
        safChkStepNm: '',
        befInspYear: "",
        chkYmd: "",
        inspCycle: "",
        nextInspMon: "",
        chkRsltSmry: '',
        relatedLaws: "",

        chkRsltCause: '',
        chkRsltContent: '',
        managerOpinion: '',
      }),
    },
    isCheck: {
      type: Boolean,
      default: function() {
        return false
      },
    },
  },
  data() {
    return {
      editable: true,
      grid2: {
        columns: [
          {
            name: 'checkItemNm',
            field: 'checkItemNm',
            label: '점검항목',
            align: 'center',
            sortable: true
          },
          {
            name: 'checkResult',
            field: 'checkResult',
            label: '점검결과',
            align: 'center',
            style: 'width:200px',
            sortable: true
          },
          {
            name: 'inspMethod',
            field: 'inspMethod',
            label: '검사방법',
            align: 'center',
            style: 'width:200px',
            sortable: true
          },
          {
            name: 'criteria',
            field: 'criteria',
            label: '판정기준',
            align: 'center',
            sortable: true
          },
          {
            name: 'safetyInfo',
            field: 'safetyInfo',
            label: '점검시 안전사항',
            align: 'center',
            sortable: true
          },
        ],
        data: [],
        height: '500px'
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      chkResultItems: [],
      chkRsltYnItems: [],
      safFacilityItems: [],
      title: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.safFacilityItems = [
        { code: '1', codeName: '자체(일상)점검' },
        { code: '2', codeName: '법정(자체)점검' },
      ];
      this.chkResultItems = [
        { code: '1', codeName: '적합' },
        { code: '2', codeName: '부적합' },
      ];
      this.chkRsltYnItems = [
        { code: 'Y', codeName: '완료' },
        { code: 'Y', codeName: '미완료' },
      ]
      this.grid2.data = [
        {
          checkItemNm: '점검항목1',
          checkResult: '점검결과1',
          inspMethod: '검사방법1',
          criteria: '판정기준1',
          safetyInfo: '점검시 안전사항1',
        },
        {
          checkItemNm: '점검항목2',
          checkResult: '점검결과2',
          inspMethod: '검사방법2',
          criteria: '판정기준2',
          safetyInfo: '점검시 안전사항2',
        },
      ];
    },
    rowClick(row) {
      this.selectedRow = this.$_.clone(row);
    },
    add() {
      // 초기에는 userId에 tempId를 부여
      this.popupOptions.title = '설비 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'none'
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if(!data.equipCd) return;
      if (data) {
          this.selectedRow.plantNm = data.plantNm;
          this.selectedRow.deptNm = data.deptNm;
          this.selectedRow.chkUserNm = '';
          this.selectedRow.chkUserId = '';
          this.selectedRow.safFacilityNm = null;
          this.selectedRow.safFacilityCd = null;
          this.selectedRow.equipmentType = data.equipType;
          this.selectedRow.equipmentCd = data.equipCd;
          this.selectedRow.equipmentNm = data.equipNm;
          this.selectedRow.chkSchYmd = '';
          this.selectedRow.remark = '';
      }
    },
    callbackProxy(data, props, col) {
      if (col.name === 'chngNum') {
        this.grid.data[props.rowIndex]['chngNum'] = data.chngNum;
      }
    },
    addItem() {
      this.grid2.data.splice(0, 0, {
        checkItemSeq: 0,
        checkItemNm: '', // 점검항목
        checkResult: '', // 점검결과
        inspMethod: '', // 검사방법
        criteria: '', // 판정기준
        safetyInfo: '', // 점검시 안전사항
      })
    },
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid2.data = this.$_.reject(this.grid2.data, item)
        })
      }
    },
    btnRecord() {
      this.popupOptions.target = () =>
      import(`${"@/pages/mdm/fim/equipmentPreview.vue"}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '40%';
      this.popupOptions.param = this.selectedRow;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
